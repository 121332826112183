
import { defineComponent, ref, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ProductGrid from "@/components/organisms/ProductGrid.vue";
import { Product } from "@/model/State.ts";

export default defineComponent({
  components: { ProductGrid },
  props: {
    editable: Boolean
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const filters = reactive({
      query: "",
      category: (route.params.category as string) || "",
      minprice: 0,
      maxprice: 0,
      sort: "nameAsc"
    });
    const filterOpen = ref(false);
    const items = ref(store.state.products);
    const results = ref(items.value);

    function filterSearch() {
      if (!filters.query) return (results.value = items.value);
      const queryRegex = new RegExp(filters.query, "gi");
      results.value = results.value.filter(
        (product: Product) =>
          queryRegex.test(product.description) ||
          queryRegex.test(product.product_name)
      );
    }

    function filterCategory() {
      if (!filters.category) return;
      const queryRegex = new RegExp(filters.category, "gi");
      results.value = results.value.filter((product: Product) =>
        queryRegex.test(product.brand_id)
      );
    }

    function filterPrice() {
      if (filters.minprice > 0) {
        results.value = results.value.filter(
          (product: Product) => product.price >= filters.minprice
        );
      }
      if (filters.maxprice > 0) {
        results.value = results.value.filter(
          (product: Product) => product.price <= filters.maxprice
        );
      }
    }

    function filterSort() {
      switch (filters.sort) {
        case "nameAsc":
          results.value = results.value.sort((a, b) =>
            a.product_name.localeCompare(b.product_name)
          );
          break;
        case "nameDec":
          results.value = results.value.sort((a, b) =>
            b.product_name.localeCompare(a.product_name)
          );
          break;
        case "priceAsc":
          results.value = results.value.sort((a, b) => a.price - b.price);
          break;
        case "priceDec":
          results.value = results.value.sort((a, b) => b.price - a.price);
          break;
      }
    }

    function applyFilters() {
      filterSearch();
      filterCategory();
      filterPrice();
      filterSort();
    }

    function resetFilters() {
      filters.query = "";
      filters.category = "";
      filters.minprice = 0;
      filters.maxprice = 0;
      filters.sort = "";

      applyFilters();
    }

    return {
      filterOpen,
      items,
      results,
      applyFilters,
      resetFilters,
      ...toRefs(filters)
    };
  }
});
