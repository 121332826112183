
import { defineComponent, computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

// import ProductGrid from '@/components/organisms/ProductGrid.vue';

export default defineComponent({
  // components: { ProductGrid },
  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const isDescriptionOpen = ref(false);
    const api = computed(() => process.env.VUE_APP_API_URL);
    const itemData = computed(() =>
      store.getters.getProductByPId(route.params.id)
    );

    function handleZoom(event: MouseEvent) {
      const target = event.target as HTMLDivElement;
      const width = (event.offsetX / target.clientWidth) * 100;
      const height = (event.offsetY / target.clientHeight) * 100;
      target.style.backgroundPosition = `${width}% ${height}%`;
    }

    const favourite = computed(() =>
      store.state.favourites.includes(itemData.value.id)
    );
    if (!itemData.value) router.push('/error');
    return {
      isDescriptionOpen,
      itemData,
      favourite,
      api,
      handleZoom,
    };
  },
});
